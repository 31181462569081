"use client"

import { Typography } from "@/components/ui/data-display/Typography"
import { Button } from "@/components/ui/inputs/Button"
import { Stack } from "@/components/ui/layout/Stack"
import { Toolbar } from "@/components/ui/surfaces/Toolbar"
import { useTranslation } from "@/i18n"
import { styled, useTheme } from "@mui/material/styles"
import Link from "next/link"

import de from "@/app/_messages/de.json"
import { trpc } from "@/app/_trpc/client"
import useGetMailToSupport from "@/hooks/useGetMailToSupport"
import {
  FEATURE_FLAG_ENABLE_UNFINISHED_FEATURES,
  NEXT_PUBLIC_HELP_CENTER_URL,
} from "@/utils/env"
import { getDayjsDateTimeFormat } from "@/utils/format"
import { replaceSlugs } from "@/utils/navigation"
import { Tooltip } from "@mui/material"
import dayjs from "dayjs"
import type { Route } from "next"
import { useParams } from "next/navigation"
import useAside from "../Aside/hooks/useAside"

const SiteFooterButton = styled(Button)(({ theme }) => ({
  variant: "text",
  size: "small",
  color: theme.palette.text.secondary,
}))

export interface SiteFooterProps {
  size?: "small" | "medium"
  disableSupportCenter?: boolean
}

export function SiteFooter({
  size = "medium",
  disableSupportCenter = false,
}: SiteFooterProps) {
  const { t } = useTranslation(de)
  const theme = useTheme()
  const { locale } = useParams()
  const { asideSearch } = useAside()

  const { data: systemInfo } = trpc.system.version.useQuery(undefined, {
    retry: false,
  })

  const { mailTo, isFetching: isFetchingSystemInfo } =
    useGetMailToSupport("[Dein Name]")

  const year = new Date().getFullYear().toString()

  return (
    <Toolbar data-testid={"SiteFooter"}>
      <Stack
        spacing={theme.spacing(2)}
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{ width: "100%" }}
      >
        <Tooltip
          title={t("systemVersion.fullInformation", {
            version: systemInfo?.version ?? t("systemVersion.unknown"),
            date: systemInfo?.release_date
              ? dayjs(systemInfo?.release_date).format(getDayjsDateTimeFormat())
              : t("systemVersion.unknown"),
          })}
        >
          <div>
            <Typography
              className={size === "small" ? "text-xs" : "text-sm"}
              color={theme.palette.text.disabled}
            >
              {"\u24B8"} {year} <strong>rightflow</strong>
            </Typography>
          </div>
        </Tooltip>
        {FEATURE_FLAG_ENABLE_UNFINISHED_FEATURES && (
          <>
            <SiteFooterButton
              data-testid={"SiteFooterBtnGoToImprint"}
              size={size}
            >
              <Link
                href={replaceSlugs(
                  "/[locale]/static/imprint" satisfies Route<"/[locale]/static/imprint">,
                  { locale },
                  asideSearch,
                )}
                color="text.secondary"
              >
                {t("imprint.label")}
              </Link>
            </SiteFooterButton>
            <SiteFooterButton data-testid={"SiteFooterBtnGoToTos"} size={size}>
              <Link
                href={replaceSlugs(
                  "/[locale]/static/privacy-statement" satisfies Route<"/[locale]/static/privacy-statement">,
                  { locale },
                  asideSearch,
                )}
              >
                {t("privacyStatement.label")}
              </Link>
            </SiteFooterButton>
          </>
        )}
        <a href={mailTo} target="_blank" rel="noreferrer">
          <SiteFooterButton
            data-testid={"SiteFooterBtnGoToPof"}
            disabled={isFetchingSystemInfo}
            size={size}
          >
            {t("contactSupport.label")}
          </SiteFooterButton>
        </a>
        {!disableSupportCenter && (
          <a
            href={NEXT_PUBLIC_HELP_CENTER_URL}
            target="_blank"
            rel="noreferrer"
          >
            <SiteFooterButton data-testid={"SiteFooterBtnGoToFaq"} size={size}>
              {t("supportCenter.label")}
            </SiteFooterButton>
          </a>
        )}
      </Stack>
    </Toolbar>
  )
}
